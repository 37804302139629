* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}


.wrapper {
  height: 100vh;
  background-image: url(/src/back.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.wrapper .effect {
  height: 100vh;
  backdrop-filter: blur(5px);
  background: rgba(0,0,0, .5);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
}

.wrapper .header {
  font-size: 130px;
  font-weight: 600;
  color: #FFF;
  text-align: center;
  margin-bottom: 70px;
}

.wrapper .grid {
  display: grid;
  grid-template-columns: 4fr 1fr 4fr;
}

.wrapper .photo {
  text-align: center;
}

.wrapper .photo-content {
  display: flex;
  align-items: center;
  justify-content: center;
}



.wrapper .imgClick {
  cursor: pointer;
  -webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  height: 400px;
  width: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-box-shadow: 4px 4px 41px 12px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: 4px 4px 41px 12px rgba(255, 255, 255, 0.2);
  box-shadow: 4px 4px 41px 12px rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  border: 3px solid rgba(255, 255, 255, .8);
}

.wrapper .imgClick:active {
  transform: scale(0.98) !important;
}

.wrapper .imgClick:hover {
  filter: brightness(1.1) contrast(1.1);
  transform: scale(1.01);
}

.wrapper .grid .and {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, .3);
  font-size: 200px;
}

.wrapper .name {
  font-size: 50px;
  color: #FFF;
  text-align: center;
  margin-top: 20px;
}

.wrapper .timer {
  text-align: center;
}

.wrapper .days {
  margin: 10px 0px;
}

.wrapper .cl-days {
  background-color: #FFF;
  display: inline-flex;
  font-size: 20px;
  padding: 5px 10px;
  border-radius: 5px;
}

.wrapper .cl-days span {
  font-size: 40px;
  font-weight: 900;
  margin-right: 10px;
}

.wrapper .clock {
  display: flex;
  justify-content: center;
}

.wrapper .cl-hours,
.wrapper .cl-minutes,
.wrapper .cl-seconds {
  font-size: 40px;
  font-weight: 600;
  background-color: #FFF;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 5px;
  width: 80px;
}

.wrapper .cl-step {
  color: #FFF;
  display: inline-flex;
  align-items: center;
  font-size: 40px;
  justify-content: center;
  width: 20px;
  transform: translateY(-4px);
}

.wrapper .timer {
  margin-top: 20px;
}

.tap {
  position: absolute;
  z-index: 99999999;
  animation-name: tap-animate;
  animation-timing-function: linear;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  color: #FFF;
  font-size: 40px;
  font-weight: 600;
  -webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}


@keyframes tap-animate {
  70% { opacity: 1; }
  100% { top: 0%; opacity: 0; }
}

.donate {
  color: #FFF;
  margin-bottom: 10px;
}

.bottom-donate {
  text-align: center;
  color: #FFF;
  font-size: 20px;
  margin-top: 30px;
}

















@media (max-width: 930px) { 
  .wrapper .grid {
    display: block;
  }

  .wrapper {
    height: initial !important;
  }

  .wrapper .effect {
    height: initial !important;
    padding: 30px 0px;
  }

  .wrapper .grid .and {
    font-size: 100px;
    margin: 50px 0px;
  }

  .wrapper .header {
    font-size: 100px;
    margin-bottom: 30px;
  }

  .wrapper .imgClick {
    width: 300px;
    height: 300px;
  }


}

